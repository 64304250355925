/* change project squre without width  */
/* change where the scroll ends afdter click on home projects about me in nav */
/* add skills part for about me and send about me to home */
/* change coming of the stars with somthing bettter for phone */

*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  background-color: rgb(0, 0, 0);
  /* background-color: rgb(8, 8, 8); */
  /* scrollbar-color: #007 #bada55;
  scrollbar-gutter: stable both-edges;
  scrollbar-width: thin; */
}
::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
  background-color: #202020;
 /* Adjust the border radius as needed */
}

::-webkit-scrollbar-thumb {
  
  background-color: #484848;
  border-radius: 10px; /* Adjust the border radius as needed */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.a{
  background-color: transparent;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.square{
  width: 450px;
  /* height: 400px; */
  background-color: #0c131b70;
  backdrop-filter: blur(3px);
  border-radius: 20px;
  border:1px solid #4D7E84 ;
  /* margin-bottom: 60px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin: 1000px; */
}
.p{
  color: #ffffff;
  font-size: 18px;
  background-color: transparent;
  align-content:start;
}
.center{
  background: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 400px;
  /* width: 100vh; */
  align-items: center;
  justify-content: center;
}
.projectTitle{
  font-size: 28px;
  font-weight: bold;
}
.projects{
  /* background: blue; */
  display: grid;
  gap: 30px 30px;
  grid-template-columns: repeat(2, 450px);
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  border-radius: 30px;
  background-color: transparent;
  margin-bottom: 100px;
}

.header{
  /* background-color: #000000; */
  display: flex;
  flex-direction: column;
  margin-top: 160px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  /* position: sticky;
  top: 140px; */
}
.myname{
  color:#ffffff;
  font-size: 100px;
  font-weight: bold;
  margin-top: 180px;
  background-color: transparent;

}
.dev{
  color:#929292;
  font-size: 28px;
  font-weight:500;
  margin-top: 20px;
  margin-bottom: 100px;
  background-color: transparent;
}
.aboutMe{
  color: #ffffff;
  /* position: sticky;
  top :140px; */
  background-color: transparent;
  border-bottom: 2px solid #0F1D26;
  font-size: 40px;
  
}
/* .fixedNav{
  background: yellow;
  position: fixed;
} */
.navHolder{
  /* top: 20px;
  left: 0;
  right: 0; */
  background: transparent;
  display: flex;
  /* position: fixed; */
  align-items: center;
  justify-content: center;
}
.nav{
  /* width: 1300px; */
  height: 60px;
  background-color:rgba(162, 240, 250, 0.06);
  /* opacity: 0.2; */
  position: fixed;
  top: 30px;
  z-index: 9999;
  border-radius: 40px;
  /* border:1px solid #1e3b44; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 70px 0px 70px;
  backdrop-filter: blur(10px);
  
}
.socialHolder{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 300px;
}
.social{
  /* background-color: #0F1D26; */
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.projTitle{
  color: #ffffff;
  /* position: sticky;
  top :140px; */
  background-color: transparent;
  /* background-color: rgb(8, 8, 8); */
  opacity: 0.8;
  font-size: 56px;
  font-weight: bold;
  border-bottom: 2px solid #0F1D26;
  margin-bottom: 100px;
  /* width: 100vh; */
}
.componentsHolder{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
  
}
.component{
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  /* padding: 10px; */
  padding-left: 30px;
  padding-right: 30px;;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;

}
.home{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;

}
.title{
  color:#ffffff;
  font-size: 100px;
  font-weight: bold;
  background-color: transparent;
}
.holder{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100vh;
}

@media screen and (max-width: 1550px) {
  .projects{
    grid-template-columns: repeat(1, 450px);
  }
}
@media screen and (min-width: 700px) {
  .image{
    width: 420px;
    height: 200px;
    margin:15px 5px 0px 5px;
  }
  .desc{
    width: 380px;
    margin-bottom: 30px;
  }
  .description{
    font-size: 16px;
  }
  .square{
    height: 400px;

  }
}
@media screen and (max-width: 700px) {
  .projects{
    grid-template-columns: repeat(1, 350px);
  }
  .component{
    font-size: 10px;
    padding-left: 15px;
    padding-right: 15px;;
  }
  .nav{
    padding: 0px 40px 0px 40px;
  }
  .title{
    font-size: 70px;
  }
  .myname{
    font-size: 70px;
  }
  .aboutMe{
    font-size: 26px;
  }
  .dev{
    font-size: 18px;
  }
  .social{
    width: 30px;
    height: 30px;
  }
  .projTitle{
    font-size: 26px;
  }
  .square{
    width: 350px;
    height: 300px;
  }
  .image{
    width: 320px;
    height: 160px;
    margin:15px 5px 0px 5px;

  }
  .desc{
    width: 300px;
    margin-bottom: 20px;
  }
  .description{
    font-size: 10px;
  }
}
@media screen and (max-width: 450px) {
  .projects{
    grid-template-columns: repeat(1, 300px);
  }
  .component{
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;;
  }
  .nav{
    padding: 0px 30px 0px 30px;
  }
  .title{
    font-size: 90px;
  }
  .myname{
    font-size: 50px;
    margin-top: 80px;
  }
  .aboutMe{
    font-size: 26px;
  }
  .dev{
    font-size: 16px;
  }
  .social{
    width: 20px;
    height: 20px;
  }
  .projTitle{
    font-size: 26px;
    margin-bottom: 20px;
  }
  .square{
    width: 300px;
    height: 280px;
  }
  .image{
    width: 280px;
    height: 150px;
    margin:10px 10px 0px 10px;
  }
  .desc{
    width: 270px;
    margin-bottom: 10px;
  }
  .description{
    font-size: 10px;
  }
  .projectTitle{
    font-size: 18px;
  }
}
@media screen and (max-width: 350px) {
  .projects{
    grid-template-columns: repeat(1, 250px);
  }
  .component{
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;;
  }
  .nav{
    padding: 0px 30px 0px 30px;
  }
  .title{
    font-size: 70px;
  }
  .myname{
    font-size: 70px;
  }
  .aboutMe{
    font-size: 26px;
  }
  .dev{
    font-size: 18px;
  }
  .social{
    width: 30px;
    height: 30px;
  }
  .projTitle{
    font-size: 26px;
  }
  .square{
    width: 250px;
    height: 210px;
  }
  .image{
    width: 230px;
    height: 160px;
    margin:10px 10px 0px 10px;
  }
  .desc{
    width: 220px;
    margin-bottom: 5px;
  }
  .description{
    font-size: 8px;
  }
  .projectTitle{
    font-size: 14px;
  }
}
/* 
@media screen and (min-width: 1550px) {
  .nav{
    z-index: 9999;
  }
}

@media screen and (max-width: 1550px) {

  .center{
    transform: scale(0.6);
  }
  .header{
    transform: scale(0.7);
    background-color: transparent;
    z-index: -1;
  }
  .projTitle{
    font-size: 40px;
  }
  .nav{
    transform: scale(0.8);
    z-index: 9999;
  }
  .home{
    transform: scale(0.8);
  }
  
}
@media screen and (max-width: 950px) {

  .center{
    transform: scale(0.9);
  }
  .header{
    transform: scale(0.7);
    background-color: transparent;
    z-index: -1;
  }
  .projTitle{
    top: 10px;
    position: sticky;
    font-size: 60px;
  }
  .nav{
    transform: scale(0.8);
    z-index: 9999;
  }
  .home{
    transform: scale(0.8);
  }
  .projects{
    grid-template-columns: repeat(1, 520px);
  }
  
}
@media screen and (max-width: 500px) {
  .center{
    transform: scale(0.7);
    z-index: -1;
  }
  .header{
    transform: scale(0.6);
    background-color: transparent;
    z-index: -1;
  }
  .projTitle{
    font-size: 40px;
    position: absolute;
    margin: 100px;
  }
  .nav{
    transform: scale(0.7);
    z-index: 9999;
  }
  .home{
    transform: scale(0.7);
  }
  .projects{
    grid-template-columns: repeat(1, 520px);
  }
  
} 
*/

#stars {
  width: 4px;
  height: 4px;
  border-radius: 30px;
  background: transparent;
  animation: animStar 50s linear infinite;
}
/* #stars :hover{
  width: 2px;
  height: 2px;
  cursor: pointer;
} */

@keyframes animStar{
  from	{
    transform: translateY(0px)}
  to		{
    transform: translateY(-2000px)}
  }


.desc{
  /* margin-bottom: 30px; */
  /* width: 380px; */
  /* height: 200px; */
  background-color: transparent;
  /* background-color: #61dafb; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.image{
  /* width: 420px; */
  /* height: 250px; */
  background-color: #4A4A4A;
  border-radius: 20px;
  /* margin:15px 5px 0px 5px; */

}
.desc-top{
  background-color: transparent;
  display: flex;
  justify-content:space-between;
  /* align-items: center; */
}
.links{
  width: 120px;
  display: flex;
  justify-content:space-around;
  background-color: transparent;

}
.link{
  width: 25px;
  height: 25px;
  background-color: transparent;
}
.desc-center{
  color:#929292;
  display: flex;
  flex-direction: column;
  justify-content: left;
  /* align-items:start; */
  align-items: flex-start;
  background-color: transparent;
}
.desc-bottom{
  color:#82AEB4;
  font-size: 20px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  background-color: transparent;

}
.description{
  align-content: start;
  background-color: transparent;
  /* font-size: 16px; */
  font-weight: 500;
  text-align: left;

}